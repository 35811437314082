import axios from 'axios'
import { API_URL } from '../Constants'

class ApiService {

  createOrder(fields) {
    return axios.post(`${API_URL}/user/createOrder`, fields);
  }

  createContact(fields) {
    return axios.post(`${API_URL}/user/createContact`, fields);
  }

  createTraining(fields) {
    return axios.post(`${API_URL}/user/createTraining`, fields);
  }

  createProject(fields) {
    return axios.post(`${API_URL}/user/createProject`, fields);
  }

}
export default new ApiService()
