import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";


import GDB1 from "assets/images/Products/GDB/GDB1.jpg"
import GDB2 from "assets/images/Products/GDB/GDB2.jpg"
import GDB3 from "assets/images/Products/GDB/GDB3.jpg"
import GDB4 from "assets/images/Products/GDB/GDB4.JPEG"

class GDB extends Component {

  render() {
    const properties = {
      thumbnailPosition: "left",
      showFullscreenButton: false,
      showPlayButton: false,
      showNav: true,

      items: [
        {
          original: GDB1,
          alt:"General Development Board Photo" ,
          thumbnail: GDB1
        },
        {
          original: GDB2,
          alt:"GDB SIde view" ,
          thumbnail: GDB2
        },
        {
          original: GDB3,
          alt:"GDB photo" ,
          thumbnail: GDB3
        },
        {
          original: GDB4,
          alt:"GDB sample application" ,
          thumbnail: GDB4
        }
      ]
    };

    return <ImageGallery {...properties} />;
  }
}

export default GDB;
