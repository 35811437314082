import  React, { useEffect } from "react";
import Aos from "aos"
import MetaTags from "react-meta-tags"
import { Row, Col } from "reactstrap";

import 'assets/style/Style.css'
import './IndexHeader.css'

import Home from 'assets/images/HeaderImages/Home.png'
import SI from 'assets/images/HeaderImages/SI.png'
import SK from 'assets/images/HeaderImages/SK.png'
import DS from 'assets/images/HeaderImages/DS.png'
import Niam from 'assets/images/HeaderImages/Niam.png'
import JNNCE from "assets/images/Clients/PNG/Jnnce.png"
import SupBy from "assets/images/HeaderImages/supported_by.png"

function IndexHeader() {

  useEffect( () => {
    Aos.init({ })
  }, [])

  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div className="page-header clear-filter" filter-color="white">

        <MetaTags>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        </MetaTags>
        <div
          ref={pageHeader}
        >
          <div className="indexheader_headerimage" >
          <div data-aos="fade-up-left" class="hero__image"></div>
            <Row>
              <Col>
                <h1 data-aos="fade-right" className='indexheader_fontxirod'>
                <br/>
                <span className='indexheader_fontcolor' > Ekathva </span>Innovations
                </h1>
                <div data-aos="fade-left" className='indexheader_taglinespacing' >
                  <p><strong className='indexheader_tagfonts'>Invent for Betterment</strong></p>

                  <div className='indexheader_logosizing'>
                  <Row>
                  <Col>
                  <p><strong className='supportedByFontcolor'>Supported By</strong></p>
                  </Col>
                  </Row>
                    <Row>
                    <Col>
                    <img src={SupBy} alt='Startup India Logo' className='indexheder_imagesize' />
                    </Col>
                      {/*
                      <Col><img src={SI} alt='Startup India Logo' className='indexheder_imagesize' /> </Col>
                      <Col><img src={SK} alt='Startup Karnataka Logo' className='indexheder_imagesize' /> </Col>
                      <Col><img src={DS} alt='Deshpande Startups Logo' className='indexheder_imagesize' /> </Col>
                      <Col><img src={Niam} alt='NIAM Logo' className='successstories_JNNCEimage' /> </Col>
                      <Col><img  src={JNNCE} alt='JNNCE Logo' className='successstories_JNNCEimage' /></Col>
                      */}
                    </Row>
                  </div>
                </div>
              </Col>

              <Col>
                <img data-aos="fade-up-left" className='indexheader_headerimgpadding' src={Home}/>
              </Col>
            </Row>
          </div>
        </div>
         {/*
        <Container>
          <div className="content-center brand" >
            <img
              alt="Ekathva logo"
              className="n-logo"
              src={Home} style={{}}
            ></img>



          </div>

          <h6 className="category category-absolute" style={{paddingTop: '15%'}}>
          <br/><br/>
            Designed by{" "}
            <a href="http://invisionapp.com/?ref=creativetim" target="_blank">
              <img
                alt="..."
                className="invision-logo"
                src={require("assets/img/invision-white-slim.png")}
              ></img>
            </a>
            . Coded by{" "}
            <a
              href="https://www.creative-tim.com?ref=nukr-index-header"
              target="_blank"
            >
              <img
                alt="..."
                className="creative-tim-logo"
                src={require("assets/img/creative-tim-white-slim2.png")}
              ></img>
            </a>
            .
          </h6>
        </Container>
        */}
      </div>
    </>
  );
}

export default IndexHeader;
