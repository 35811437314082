/*eslint-disable*/
import React from "react";
import EINAV from 'assets/images/FooterImages/EILogo.jpg'


import 'assets/style/Style.css'

import { Container, Row, Col, } from "reactstrap";
import { Image  } from 'react-bootstrap'
import { Link } from "react-router-dom";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="">
    <Container>
    <Row>
    <Col md={4}>
    <nav className="footer_nav footer_socialspace">
    Connect with us
    <div className="social">
    <ul>
    <li>
    <a
    href="https://www.facebook.com/EkathvaInnovationsPvtLtd/"
    target="_blank"
    id="facebook-tooltip">
    <i id="fb" className="fab fa-facebook-square "></i>
    </a>
    </li>
    <li>
    <a
    href="https://www.instagram.com/ekathva_innovations/"
    target="_blank"
    id="instagram-tooltip"
    >
    <i id="insta" className="fab fa-instagram"></i>
    </a>
    </li>
    <li>
    <a
    href="https://youtube.com/@ekathvainnovations3986"
    target="_blank"
    id="youtube-tooltip">
    <i id="fb" className="fab fa-youtube "></i>
    </a>
    </li>
    <li>
    <a
    href="https://www.linkedin.com/company/ekathva-innovations"
    target="_blank"
    id="linkedin-tooltip"
    >

    <i id="lnkdin" className="fab fa-linkedin"></i>
    </a>
    </li>
    <li>
    <a
    href="https://api.whatsapp.com/send?phone=919663805228"
    target="_blank"
    id="linkedin-tooltip"
    >
    <i id="whatsapp" className="fab fa-whatsapp"></i>
    </a>
    </li>
    </ul>
    </div>
    </nav>

    </Col>

    <Col md={4}>
    <div className='footer_eilogospacing' >
    <Link to='/index'>
    <Image src={EINAV} className='footer_eilogosizing' />
    </Link>
    </div>
    </Col>

    <Col  md={4}>
    <div className="copyright footer_copyrightspace" id="copyright">
    <p className='footer_copyrightcontent' >
    {/* <Link to='/career' className="footer_links"> Career</Link> | */}
    <Link to='/media-gallery' className="footer_links"> Media </Link> |
    {/* <Link to='#'> Blog </Link> |*/}
    <Link to='/contact-us' className="footer_links"> Contact Us </Link> |
    <Link to='/privacy-policy' className="footer_links"> Privacy Policy </Link> |
    <Link to='/sitemap' className="footer_links"> Sitemap</Link>
    <br/>Copyright © {new Date().getFullYear()} Ekathva Innovations.<br/>All Rights Reserved
    </p>


    </div>
    </Col>
    </Row>



    </Container>
    </footer>
  );
}

export default DarkFooter;
