import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { Image } from 'react-bootstrap'
import Aos from "aos"

import Career from 'assets/images/HeaderImages/Career.png'

import 'assets/style/Style.css'

function LandingPageCareer() {

  useEffect( () => {
    Aos.init({ })
  }, [])

  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header clear-filter page-header-small">
        <div
          className="page-header-image"
          ref={pageHeader}
        >
          <div className="landing_backgroundimages" >
            <Row>
              <Col>
              <h1 data-aos="fade-right" className='landing_career' >Career</h1>
              <div data-aos="fade-left">
                <p className='landing_taglinestyle' ><strong>Become a Part of Our Amazing Team</strong></p>
              </div>
              </Col>
              <Col>
                <Image data-aos="fade-up-left" className='landing_aboutusimage' src={Career} roundedCircle/>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default LandingPageCareer;
