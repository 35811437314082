import React,{useState} from "react";
import { Link } from 'react-router-dom'
import { NavDropdown } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'

import EILogo from 'assets/images/NavbarImages/EILogo.png'

import 'assets/style/Style.css'
import 'components/Navbars/IndexNavbar.css'
// reactstrap components
import {
  Row,
  Col,
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  NavLink,
  Container,
  UncontrolledTooltip,
} from "reactstrap";


function IndexNavbar() {

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
  setNavbarCollapse(!navbarCollapse);
  document.documentElement.classList.toggle("nav-open");
};

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 9 ||
        document.body.scrollTop > 9
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 10 ||
        document.body.scrollTop < 10
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const [show, setShow] = useState(false);
  const showDropdown = (e)=>{
    setShow(!show);
  }
  const hideDropdown = e => {
    setShow(false);
  }


  return (
    <>

    <Navbar
    className='ml-auto'
    className={"fixed-top " + navbarColor}
    expand="lg"
    color='white'>

    <Link to="/index">
      <img src={EILogo} alt='Ekathva Innovations logo' className='navbar_eilogo' />
    </Link>

            <Link to="/index" className="navbarlink">
            <p>
            <span className='navbar_fonts'>Ekathva</span><br class="d-md-none" />
            <span className='navbar_subfonts'> Innovations</span>
            </p>
          </Link>

    <Container>

    <div className="navbar-translate">

    <NavbarBrand
    href="/Index"
    data-placement="bottom"
    id="navbar-brand"
    className="navbar-transparent"
    >
    <Row>
    <Col>
    </Col>
    <Col>
    </Col>
    </Row>
    </NavbarBrand>

    <button
    className="navbar-toggler ml-auto customToggler"
    onClick={toggleNavbarCollapse}
    aria-expanded={navbarCollapse}
    type="button"
    >
    <span className="navbar-toggler-bar top-bar"></span>
    <span className="navbar-toggler-bar middle-bar"></span>
    <span className="navbar-toggler-bar bottom-bar"></span>
    </button>
    </div>
    <Collapse
    className="justify-content-end"
    isOpen={navbarCollapse}
    navbar
    >

    <Nav navbar>

    <NavItem>
    <NavLink to='/index' className='main-nav' tag={Link} activeClassName='main-nav-active'>
    <p className=""><strong>Home</strong></p>
    </NavLink>
    </NavItem>


    <NavItem>
    <NavLink to='/about-us' tag={Link}>
    <p className=""><strong>About</strong></p>
    </NavLink>
    </NavItem>

    <NavItem>
    <NavLink to='/products' tag={Link}>
    <p className=""><strong>Products</strong></p>
    </NavLink>
    </NavItem>

    <NavDropdown className='navbar_dropdowncolor' title="Services"
    id="collasible-nav-dropdown" show={show} onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>

    <NavDropdown.Item>
    <NavLink to="/embedded-services" tag={Link}>
    <p className="navbar_navlinikfontAfterScroll"><strong>Embedded</strong></p>
    </NavLink>
    </NavDropdown.Item>

    <NavDropdown.Item>
    <NavLink to="/it-services" tag={Link}>
    <p className="navbar_navlinikfontAfterScroll"><strong>IT Services</strong></p>
    </NavLink>
    </NavDropdown.Item>

    </NavDropdown>


    <NavItem>
    <NavLink to='/success-story' tag={Link}>
    <p className=""><strong>Success Stories</strong></p>
    </NavLink>
    </NavItem>

    <NavItem>
    <NavLink to='/contact-us' tag={Link}>
    <p className=""><strong>Contact</strong></p>
    </NavLink>
    </NavItem>

    </Nav>
    </Collapse>
    </Container>
    </Navbar>
    </>
  );
}

export default IndexNavbar;
