import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";


import SML1 from "assets/images/Products/MoistureLogger/SML1.jpg"
import SML2 from "assets/images/Products/MoistureLogger/SML2.jpeg"
import SML3 from "assets/images/Products/MoistureLogger/SML3.jpg"

class MositureLogger extends Component {

  render() {
    const properties = {
      thumbnailPosition: "left",
      showFullscreenButton: false,
      showPlayButton: false,
      showNav: true,

      items: [
        {
          original: SML2,
          alt: "Moisture Logger",
          thumbnail: SML2
        },
        {
          original: SML1,
          alt: "Solar Powered moisture sensor",
          thumbnail: SML1
        },
        {
          original: SML3,
          alt: "soil moisture sensor dashboard",
          thumbnail: SML3
        }
      ]
    };

    return <ImageGallery {...properties} />;
  }
}

export default MositureLogger;
