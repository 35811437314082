import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";


import PSR1 from "assets/images/Products/PSR/PSR1.JPEG"
import PSR2 from "assets/images/Products/PSR/PSR2.JPEG"
import PSR3 from "assets/images/Products/PSR/PSR3.JPEG"

class PSR extends Component {

  render() {
    const properties = {
      thumbnailPosition: "left",
      showFullscreenButton: false,
      showPlayButton: false,
      showNav: true,

      items: [
        {
          original: PSR1,
          alt: "Power supply module",
          thumbnail: PSR1
        },
        {
          original: PSR2,
          alt: "Multiple regulated Power supply module",
          thumbnail: PSR2
        },
        {
          original: PSR3,
          alt: "DC Power supply regulator",
          thumbnail: PSR3
        }
      ]
    };

    return <ImageGallery {...properties} />;
  }
}

export default PSR;
