import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";


import ITL1 from "assets/images/Products/ITL/ITL1.jpg"
import ITL2 from "assets/images/Products/ITL/ITL2.jpg"
import ITL3 from "assets/images/Products/ITL/ITL3.jpg"
import ITL4 from "assets/images/Products/ITL/ITL4.jpg"

class IndustrialTimeLogger extends Component {

  render() {
    const properties = {
      thumbnailPosition: "left",
      showFullscreenButton: false,
      showPlayButton: false,
      showNav: true,

      items: [
        {
          original: ITL1,
          alt:"IoT Hardware for Industry 4.0" ,
          thumbnail: ITL1
        },
        {
          original: ITL2,
          alt: "Wireless Time logger device",
          thumbnail: ITL2
        },
        {
          original: ITL3,
          alt: "Admin side analytical dashboard",
          thumbnail: ITL3
        },
        {
          original: ITL4,
          alt:"User side data entry portal" ,
          thumbnail: ITL4
        }
      ]
    };

    return <ImageGallery {...properties} />;
  }
}

export default IndustrialTimeLogger;
