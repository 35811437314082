import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";


import SV1 from "assets/images/Products/SmartValve/SV1.jpeg"
import SV2 from "assets/images/Products/SmartValve/SV2.jpeg"
import SV3 from "assets/images/Products/SmartValve/SV3.jpeg"

class SmartValve extends Component {

  render() {
    const properties = {
      thumbnailPosition: "left",
      showFullscreenButton: false,
      showPlayButton: false,
      showNav: true,

      items: [
        {
          original: SV1,
          alt: "Smart Valve Controller",
          thumbnail: SV1
        },
        {
          original: SV2,
          alt:"Solenoid Valve",
          thumbnail: SV2
        },
        {
          original: SV3,
          alt:"Mobile and Dashboard",
          thumbnail: SV3
        }
      ]
    };

    return <ImageGallery {...properties} />;
  }
}

export default SmartValve;
