import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";


import DT1 from "assets/images/Products/Dtracker/DT1.jpg"
import DT2 from "assets/images/Products/Dtracker/DT2.jpg"
import DT3 from "assets/images/Products/Dtracker/DT3.jpg"

class DTracker extends Component {

  render() {
    const properties = {
      thumbnailPosition: "left",
      showFullscreenButton: false,
      showPlayButton: false,
      showNav: true,

      items: [
        {
          original: DT1,
          alt:"D-Tracker Software" ,
          thumbnail: DT1
        },
        {
          original: DT2,
          alt:"D-Tracker Login page" ,
          thumbnail: DT2
        },
        {
          original: DT3,
          alt:"D-Tracker Logo" ,
          thumbnail: DT3
        }
      ]
    };

    return <ImageGallery {...properties} />;
  }
}

export default DTracker;
